<template>
    <div>
        <games-component />
    </div>
</template>

<script>
import GamesComponent from '@/components/admin/games/gamesList'
export default {
    name: "AdminGames",
    components: { GamesComponent },
    data: () => ({

    })
};
</script>
